.carousel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    overflow: hidden;
    position: relative;
    font-size: 32px;
    font-weight: bold;
    width: 280px;
    height: 40px;
    transition: width 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
    @media (max-width: 440px) {
      font-size: 28px;
    }
  }
  
  .text {
    position: absolute;
    width: max-content;
    text-align: center;
    transition: transform 0s ease-in-out;
    transform: translateY(100%);
    background-image: linear-gradient(90deg, #6acec9 22%, #76bce5 31.08%, #8bbfae 39.01%, #33d2ba 47.31%, #a1d4b9 60.65%);
    background-size: 100%;
    background-clip: text;
    color: transparent;
  }
  
  .slide-in {
    opacity: 1;
    transform: translateY(0);
    transition: transform 0.4s ease-in-out;
  }
  
  .slide-out {
    transform: translateY(-100%);
    transition: transform 0.4s ease-in-out;
  }