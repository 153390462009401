.hotkeys-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 44px;
    gap: 24px;
    transform: scale(1.2);
    margin-top: 24px;
    @media (max-width: 900px) {
        margin-top: 0px;
        transform: scale(1);
    }
    .top-row {
        display: flex;
        align-items: center;
        gap: 12px;
        height: min-content;
    }
    .bot-row {
        display: flex;
        align-items: center;
        gap: 12px;
    }
    .kb-box {
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 1;
        transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
        overflow: hidden;
        img {
            box-shadow: 0px 4px 24px 0px rgba($color: #000000, $alpha: .04);
        }
        &.show {
            display: flex;
            opacity: 1;
            transform: translateY(0%);
        }
        &.hide {
            width: 0px;
            height: 0px;
            opacity: 0;
            transform: translateY(-50%);
        }
    }
    .kb-collapse {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 16px;
        cursor: pointer;
        height: 50px;
        width: 50px;
        border: 1px solid #E1E1E1;
        background-color: white;
        transition: transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
        &.animate-pulse {
            animation: pulsate 2s infinite;
        }
        @keyframes pulsate {
            0% {
                box-shadow: 0 0 0 0 rgba($color: #61C2A0, $alpha: 0.5);
            }
            50% {
                box-shadow: 0 0 0 8px rgba($color: #61C2A0, $alpha: 0);
                
            }
            100% {
                box-shadow: 0 0 2px 0 rgba($color: #61C2A0, $alpha: 0);
            }
        }
        &:hover {
            transform: translateY(-4px);
        }
        .icon-box {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }    
}

.badges-wrapper {
    display: grid;
    gap: 32px;
    justify-content: center;
    padding: 64px 12px;
    grid-template-columns: repeat(1, 210px);

    @media (min-width: 780px) {
        grid-template-columns: repeat(2, 210px);
    }

    @media (min-width: 1200px) {
        grid-template-columns: repeat(4, 210px);
    }
    .badge {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 210px;
        height: 210px;
        border-radius: 200px;
        background-color: #fff;
        box-shadow: 0px 4px 32px 0px rgba($color: #000000, $alpha: .04);

    }
}