.portfolio-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .intro-banner {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: rgb(52, 52, 52);
        width: 100%;
        box-shadow: 0px 0px 24px 0px rgba(0,0,0,0.3) inset;
        background: linear-gradient(0deg, #2a2a2a 0%, #303030 100%);
        position: relative;
        background-image: radial-gradient(farthest-side at 50% 100%, #0f4b44 0%, #2a2a2a 100%);
        &::after {
            content: '';
            position: absolute;
            bottom: 0px;
            height: 2px;
            width: 100%;
            background: linear-gradient(90deg, #0e4c4500 0%, #268d81 50%, #0e4c4500 100%);
        }
        &::before {
            content: '';
            position: absolute;
            max-width: 400px;
            bottom: 16%;
            height: 2px;
            width: 70%;
            background: linear-gradient(90deg, #0e4c4500 0%, #268d81aa 50%, #0e4c4500 100%);
        }
        .intro-banner-content {
            padding: 64px 24px;
            gap: 12px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: white;
            text-align: center;
            border: 1px solid #ffffff10;
            @media (max-width: 820px) {
                gap: 8px;
                padding: 44px 16px;
            }
            .title {
                font-size: 28px;
                font-weight: 500;
                @media (max-width: 820px) {
                    font-size: 20px;
                }
            }
            .subtitle {
                opacity: .76;
                font-weight: 300;
                font-size: 18px;
                @media (max-width: 820px) {
                    font-size: 14px;
                }
            }
        }
    }
    .hero {
        width: 100%;
        box-sizing: border-box;
        align-items: center;
    }
}