.credentials-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    // max-height: 400px;
    height: 440px;
    width: 440px;
    @media (max-width: 440px) {
        width: 340px;
    }
    .credential-container {
        position: absolute;
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 24px;
        border-radius: 20px;
        box-shadow: 0px 20px 44px 8px rgba($color: #000000, $alpha: .04);
        background-color: white;
        width: 440px;
        transition: all 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
        overflow: hidden;
        opacity: 1;
        transform-origin: top center;
        text-align: left;
        @media (max-width: 440px) {
            width: 340px;
            gap: 12px;
        }
        &:nth-child(1) { // The first card in the DOM is the last one visually
            opacity: 0;
            animation: fadeIn 0.5s ease forwards;
        }
        @keyframes fadeIn {
            from {
              opacity: 0;
              top: -60px; // Adjust starting position for the fade-in effect
            }
            to {
              opacity: 1;
              top: 0px;
            }
        }
        .shimmer-box {
            position: absolute;
            display: flex;
            width: 100%;
            height: 100%;
            top: 0px;
            left: 0px;
            transform-origin: right;
            transform: scaleX(1);
            opacity: 1;
            animation-name: hideShimmerBox;
            animation-duration: 0.6s;
            animation-fill-mode: forwards;
            &.remain-hidden {
                transform: scaleX(0);
                animation: unset;
            }
            @keyframes hideShimmerBox {
                0% {
                    transform: scaleX(1);
                }
                100% {
                    transform: scaleX(0);
                }
            }
            .shimmer-element {
                display: flex;
                width: 100%;
                height: 100%;
                background: #e1e1e1;
                background: linear-gradient(90deg, rgba(244,244,244,1) 0%, rgba(228,228,228,1) 25%, rgba(244,244,244,1) 50%, rgba(228,228,228,1) 75%, rgba(244,244,244,1) 100%);
                background-size: 200% 200%;
                border-radius: 4px;
                animation: shimmer 2s infinite;
            }
        }
        .header {
            padding-bottom: 4px;
            display: flex;
            align-items: center;
            .wrap {
                display: flex;
                flex-direction: column;
                gap: 8px;
                .title {
                    font-size: 24px;
                    color: #000000;
                    font-weight: 500;
                    position: relative;
                    @media (max-width: 440px) {
                        font-size: 18px;
                    }
                    .shimmer-box {
                        width: 120%;
                    }
                }
                .category {
                    font-size: 18px;
                    font-weight: 700;
                    position: relative;
                    @media (max-width: 440px) {
                        font-size: 16px;
                    }
                    .shimmer-box {
                        width: 60%;
                    }
                }
            }
        }
        .info-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .item {
                display: flex;
                flex-direction: column;
                gap: 2px;
                .label {
                    font-size: 14px;
                    color: #808080;
                    font-weight: 400;
                    position: relative;
                    @media (max-width: 440px) {
                        font-size: 12px;
                    }
                    .shimmer-box {
                        width: 100%;
                    }
                }
                .value {
                    font-size: 16px;
                    color: black;
                    font-weight: 500;
                    position: relative;
                    @media (max-width: 440px) {
                        font-size: 14px;
                    }
                    .shimmer-box {
                        width: 140%;
                    }
                }
                &:nth-child(2n) {
                    text-align: right;
                    .value, .label {
                        .shimmer-box {
                            left: unset;
                            right: 0px;
                            transform-origin: right;
                        }
                    }
                }
            }
        }
    }
}

@keyframes shimmer {
    0%{
        background-position: 100% 0%;
    }
    100% {
        background-position: -100% 0%;
    }
}