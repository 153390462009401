.cards-grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(264px, 1fr));
    gap: 24px;
    width: 100%;
    padding: 0px 88px;
    box-sizing: border-box;
    position: relative;
    @media (max-width: 1200px) {
        padding: 0px 24px;
    }
    @media (max-width: 820px) {
        padding: 0px;
    }
    .grid-item {
        display: flex;
        flex-direction: column;
        padding: 24px;
        padding-bottom: 32px;
        background-color: #FFF;
        border-radius: 16px;
        flex-grow: 1;
        align-items: center;
        gap: 12px;
        line-height: 1.4;
        text-align: center;
        transition: all 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.04);
        &.in-view {
            opacity: 1;
            transform: translateY(0);
        }
        &.hide-in-view {
            opacity: 0;
            transform: translateY(60px);
        }
        .item-icon-box {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #f8f8f8;
            border-radius: 44px;
            width: 84px;
            height: 84px;
        }
        .item-title {
            font-size: 20px;
            color: black;
            font-weight: 500;
            margin-top: 8px;
        }
        .item-subtitle {
            font-size: 16px;
            color: black;
            opacity: 0.64;
            font-weight: 400;

        }
    }
}