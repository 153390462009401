body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  width: 1080px;
  padding-left: 16px;
  padding-right: 16px;
  @media (max-width: 1200px) {
    width: 780px;
  }
  @media (max-width: 820px) {
    width: calc(100vw - 24px);
  }
}
.container-border {
  border-right: 1px solid rgba(110, 96, 96, 0.16);
  border-left: 1px solid rgba(110, 96, 96, 0.16);
}
.container-width {
  width: 1080px;
  @media (max-width: 1200px) {
    width: 780px;
  }
  @media (max-width: 820px) {
    width: calc(100vw - 24px);
  }
}
.container-wide {
  width: 1200px;
  padding-left: 16px;
  padding-right: 16px;
  @media (max-width: 1200px) {
    width: 900px;
  }
  @media (max-width: 900px) {
    width: calc(100vw - 24px);
  }
}
.container-wide-border {
  border-right: 1px solid rgba(110, 96, 96, 0.16);
  border-left: 1px solid rgba(110, 96, 96, 0.16);
}
.container-wide-force {
  .container {
    width: 1200px;
    padding-left: 16px;
    padding-right: 16px;
    border-right: 1px solid rgba(110, 96, 96, 0.16);
    border-left: 1px solid rgba(110, 96, 96, 0.16);
    @media (max-width: 1200px) {
      width: 900px;
    }
    @media (max-width: 900px) {
      width: calc(100vw - 24px);
    }
  }
  .container-width {
    width: 1200px;
    @media (max-width: 1200px) {
      width: 900px;
    }
    @media (max-width: 900px) {
      width: calc(100vw - 24px);
    }
  }
}


