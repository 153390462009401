.contained-banner-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 64px 16px; 
    .contained-banner-box {
        border-radius: 16px;
        background-color: #2a2a2a;
        box-shadow: 0px 12px 44px 0px rgba(0, 0, 0, 0.06);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 44px 24px;
        flex-direction: column;
        gap: 12px;
        flex-grow: 1;
        text-align: center;
        background-image: radial-gradient(farthest-side at 50% 100%, #0e4c45 0%, #212222 100%);
        transition: all .8s cubic-bezier(0.075, 0.82, 0.165, 1) 0.3s;
        position: relative;
        box-shadow: 0px 16px 24px -24px #2ea093;
        &::after {
            content: '';
            position: absolute;
            bottom: 0px;
            height: 2px;
            width: 100%;
            background: linear-gradient(90deg, #0e4c4500 0%, #268d81 50%, #0e4c4500 100%);
        }
        &.in-view {
            opacity: 1;
            transform: translateY(0);
        }
        &.hide-in-view {
            opacity: 0;
            transform: translateY(60px);
        }
        .label {
            color: aquamarine;
            font-weight: 600;
            font-size: 16px;
        }
        .title {
            color: white;
            font-size: 20px;
            max-width: 440px;
        }
        .subtitle {
            color: rgba($color: white, $alpha: 0.7);
            font-size: 16px;
            font-weight: 300;
            max-width: 440px;
        }
    }
}