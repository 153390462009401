.logo-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 180px;
    // width: 100%;
    // height: 100%;
    background-color: black;
    border-radius: 50%;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 1000;
    transform: translate(-50%, -50%) scale(1.4);
    transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    box-shadow: 0px 0px 0px 2000px #000;
    animation-name: fadeContainer;
    animation-duration: 3.6s;
    animation-iteration-count: 1;
    animation-delay: .3s;
    animation-fill-mode: forwards;
    pointer-events: none;

    &.animation-ended {
        display: none;
    }

    .col {
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 30px;
        height: 90px;
        &:nth-child(1) {
            animation-delay: .3s;
            animation-duration: 3.6s;
            animation-iteration-count: 1;
            animation-name: deployCol1;
            animation-fill-mode: forwards;  
        }
        &:nth-child(3) {
            animation-delay: .3s;
            animation-duration: 3.6s;
            animation-iteration-count: 1;
            animation-name: deployCol2;
            animation-fill-mode: forwards;  
        }
        .item {
            width: 30px;
            height: 90px;
            background: white;
            &.i9-1 {
                height: 55px;
                // border-radius: 30px 0px 0px 30px;
                animation-delay: .3s;
                animation-duration: 3.6s;
                animation-iteration-count: 1;
                animation-name: i91Shape;
                animation-fill-mode: forwards;  
            }
            &.i9-2 {
                height: 30px;
                // border-radius: 0px 0px 0px 100px;
                background: linear-gradient(180deg, #FFFFFFFF 18%, #FFFFFF00 100%);
                animation-delay: .3s;
                animation-duration: 3.6s;
                animation-iteration-count: 1;
                animation-name: i92Shape;
                animation-fill-mode: forwards;  
            }
            &.in-1 {
                background: linear-gradient(180deg, #FFFFFFFF 18%, #FFFFFF00 100%);
                // border-radius: 100px 0px 100px 0px;
                animation-delay: .3s;
                animation-duration: 3.6s;
                animation-iteration-count: 1;
                animation-name: in1Shape;
                animation-fill-mode: forwards;  
            }
            &.in-2 {
                // border-radius: 0px 100px 100px 0px;
                animation-delay: .3s;
                animation-duration: 3.6s;
                animation-iteration-count: 1;
                animation-name: in2Shape;
                animation-fill-mode: forwards;  
            }
        }
    }
}

@keyframes fadeContainer {
    0% {
        transform: translate(-50%, -50%) scale(1.4);
        box-shadow: 0px 0px 0px 2000px #000;
        pointer-events: all;
    }
    30% {
        box-shadow: 0px 0px 0px 2000px #000;
    }
    50% {
        box-shadow: 0px 0px 0px 0px #000;
    }
    60% {
        transform: translate(-50%, -50%) scale(1.4);
        opacity: 1;
    }
    69% {
        transform: translate(-50%, -500%) scale(1.4);
        opacity: 0;
        box-shadow: 0px 0px 0px 0px #000;
    }
    70% {
        opacity: 0;
        display: none;
        box-shadow: 0px 0px 0px 0px #000;
        z-index: 100;
    }
    100% {
        opacity: 0;
        display: none;
        box-shadow: 0px 0px 0px 0px #000;
        pointer-events: none;
        z-index: -1;
    }
}
@keyframes deployCol1 {
    0% {
        transform: translateX(0px);
    }
    20%, 100% {
        transform: translateX(-5px);
    }
    // 80% {
    //     transform: translateX(-5px);
    // }
    // 100% {
    //     transform: translateX(0px);
    // }
}
@keyframes deployCol2 {
    0% {
        transform: translateX(0px);
    }
    20%, 100% {
        transform: translateX(5px);
    }
    // 80% {
    //     transform: translateX(5px);
    // }
    // 100% {
    //     transform: translateX(0px);
    // }
}
@keyframes i91Shape {
    0% {
        border-radius: 0px 0px 0px 0px;
    }
    40%, 100% {
        border-radius: 100px 0px 0px 100px;
    }
    // 80% {
    //     border-radius: 100px 0px 0px 100px;
    // }
    // 100% {
    //     border-radius: 0px 0px 0px 0px;
    // }
}
@keyframes i92Shape {
    0% {
        border-radius: 0px 0px 0px 0px;
    }
    40%, 100% {
        border-radius: 0px 0px 0px 100px;
    }
    // 80% {
    //     border-radius: 0px 0px 0px 100px;
    // }
    // 100% {
    //     border-radius: 0px 0px 0px 0px;
    // }
}
@keyframes in1Shape {
    0% {
        border-radius: 0px 0px 0px 0px;
    }
    40%, 100% {
        border-radius: 100px 0px 100px 0px;
    }
    // 80% {
    //     border-radius: 100px 0px 100px 0px;
    // }
    // 100% {
    //     border-radius: 0px 0px 0px 0px;
    // }
    
}
@keyframes in2Shape {
    0% {
        border-radius: 0px 0px 0px 0px;
    }
    40%, 100% {
        border-radius: 0px 100px 100px 0px;
    }
    // 80% {
    //     border-radius: 0px 100px 100px 0px;
    // }
    // 100% {
    //     border-radius: 0px 0px 0px 0px;
    // }

}