.footer-box {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #2A2A2A;
    background-image: radial-gradient(farthest-side at 50% 0%, #092d29 0%, #2a2a2a 80%);
    border-top: 1px solid black;
    .footer-container {
        display: flex;
        flex-direction: column;
        padding: 44px 88px 64px 88px;
        @media (max-width: 440px) {
            padding: 44px 24px 64px 24px;
        }
        .footer-header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 64px;
        }
        .footer-content-wrap {
            display: flex;
            justify-content: space-between;
            flex-grow: 1;
            flex-wrap: wrap;
            gap: 32px;
            @media (max-width: 440px) {
                flex-direction: column;
                justify-content: start;
                flex-wrap: nowrap;
            }
            .content-column {
                display: flex;
                flex-direction: column;
                .label {
                    font-size: 16px;
                    color: #A0A0A0;
                    font-weight: 600;
                    margin-bottom: 16px;
                }
                .item {
                    font-size: 18px;
                    color: white;
                    font-weight: 300;
                    margin-bottom: 10px;
                    cursor: pointer;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}