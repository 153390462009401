.home {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow: hidden;
}
.hero-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    // background-color: #f0f0f0;
    width: 100%;
    overflow: hidden;
    border-top: 1px solid rgba(110, 96, 96, 0.16);
    border-bottom: 1px solid rgba(110, 96, 96, 0.16);
    .spline-scene-wrapper {
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
    }
}

.horizontal-brand-line {
    width: 10px;
    height: 1px;
    transform: scaleX(1000);
    background-color: rgba(110, 96, 96, 0.16);
    position: absolute;
}
.vertical-brand-line {
    width: 1px;
    height: 10px;
    transform: scaleY(1000);
    background-color: rgba(110, 96, 96, 0.16);
    position: absolute;
}
.hero {
    z-index: 2;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    pointer-events: none;
    gap: 44px;
    .hero-sections {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        gap: 88px;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 44px;
        padding-top: 64px;
        @media (max-width: 1200px) {
            gap: 24px;
        }
        @media (max-width: 440px) {
            padding-top: 88px;
            padding-bottom: 64px;
        }
        &.mobile-layout {
            flex-direction: column;
            align-items: center;
            text-align: center;
            gap: 44px;
        }
        .hero-illustration {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 12px;
            transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1) 0.6s;
            &.in-view {
                opacity: 1;
                transform: translateX(0);
            }
            &.hide-in-view {
                opacity: 0;
                transform: translateX(-40px);
            }
            @media (max-width: 820px) {
                width: 100%;
            }
            img {
                position: absolute;
                transform: scale(0.5);
            }

            // LIQUID CASE STUDY
            &.liquid-case {
                width: 50%;
                min-width: 300px;
                min-height: 480px;
                max-width: 580px;
                background: white;
                background: radial-gradient(circle, rgba(90,235,201,.12) 0%, rgba(255,255,255,0) 60%);
                justify-content: flex-end;
                @media (max-width: 1200px) {
                    margin-right: -64px;
                }
                @media (max-width: 820px) {
                    margin-right: unset;
                }
                @media (max-width: 440px) {
                    transform: scale(0.9);
                }
                .waterfall {
                    top: 0px;
                    left: 0px;
                    filter: drop-shadow(4px 12px 64px rgba(0,0,0,0.08));
                    transform-origin: top left;
                    z-index: 1;
                }
                .dist-chart {
                    top: 24px;
                    right: 0px;
                    filter: drop-shadow(4px 12px 64px rgba(0,0,0,0.12));
                    transform-origin: top right;
                    z-index: 3;
                }
                .dist-banner-container {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 12px;
                    padding-left: 20px;
                    border-radius: 16px;
                    box-shadow: 0px 8px 44px rgba(0,0,0,0.1);
                    width: 100%;
                    background-color: white;
                    position: relative;
                    gap: 24px;
                    .shade {
                        height: 100%;
                        width: 12px;
                        background: white;
                        background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 30%);
                        position: absolute;
                        right: 0px;
                    }
                    overflow: hidden;
                    img {
                        transform: scale(1);
                        position: unset;
                    }
                }
            }

            // DENTAL CASE STUDY
            &.dental-case {
                width: 50%;
                min-width: 300px;
                min-height: 460px;
                max-width: 580px;
                background: white;
                background: radial-gradient(circle, rgba(90, 206, 235, 0.06) 0%, rgba(255,255,255,0) 60%);
                @media (max-width: 1200px) {
                    margin-right: -64px;
                    width: 64%;
                }
                @media (max-width: 820px) {
                    margin-right: unset;
                }
                @media (max-width: 440px) {
                    transform: scale(0.8);
                }
                .dental-chart {
                    -webkit-transform-origin-y: top;
                    -webkit-transform-origin-x: left;
                    top: 0px;
                    left: 0px;
                    filter: drop-shadow(0px 4px 32px rgba(0,0,0,0.04));
                    @media (min-width: 1200px) {
                        left: 50%;
                        transform: translateX(-25%) scale(0.5);
                    }
                }
                .piece-form {
                    -webkit-transform-origin-y: bottom;
                    -webkit-transform-origin-x: left;
                    bottom: 0px;
                    left: 8px;
                    filter: drop-shadow(0px 12px 44px rgba(0,0,0,0.08));
                    @media (min-width: 1200px) {
                        left: 50%;
                        transform: translateX(-25%) scale(0.5);
                    }
                }
                img {
                    @media (max-width: 820px) {
                        -webkit-transform-origin-x: center !important;
                        left: unset !important;
                    }
                }
            }
            
        }
    }
    .hero-content {
        &.project-hero {
            padding-bottom: 12px;
        }
        .product-logo-label {
            display: flex;
            align-items: center;
            font-size: 24px;
            color: #000;
            font-weight: 800;
            gap: 12px;
            @media (max-width: 820px) {
                justify-content: center;
            }
            img {
                width: 28px;
                height: 28px;
            }
        }
        .hero-title {
            &.sizing-override {
                font-size: 38px;
                @media (max-width: 820px) {
                    font-size: 32px;
                }
                @media (max-width: 440px) {
                    font-size: 24px;
                }
            }
        }
        .keywords {
            display: flex;
            flex-direction: column;
            gap: 16px;
            margin-top: 8px;
            position: relative;
            .chips-wrap {
                display: flex;
                flex-wrap: wrap;
                gap: 12px;
                @media (max-width: 820px) {
                    justify-content: center;
                }
                .chip {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #eaeaea;
                    padding: 6px 10px;
                    font-weight: 500;
                    font-size: 18px;
                    color: #525252;
                    pointer-events: all;
                    cursor: pointer;
                    transition: 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
                    &:hover {
                        color: black;
                        transform: translateY(-4px);
                        box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.04);
                    }
                    @media (max-width: 440px) {
                        font-size: 14px;
                        padding: 6px 8px;
                    }
                }
            }
            .read-case {
                display: flex;
                align-items: center;
                gap: 12px;
                font-size: 20px;
                color: black;
                font-weight: 500;
                padding: 24px 0px;
                margin-bottom: -16px;
                text-decoration: underline;
                pointer-events: all;
                cursor: pointer;
                transition: transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
                @media (max-width: 820px) {
                    justify-content: center;
                }
                &:hover {
                    gap: 16px;
                    transform: translateX(4px);
                }
            }
        }
    }
}

.case-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    .text-block-section-wrap {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        background-color: #FAFAFA;
        border-bottom: 1px solid #EAEAEA;
        &:nth-child(2n-1) {
            background-color: #FFFFFF;
        }
        .text-block-content {
            flex-direction: column;
            display: flex;
            padding: 64px 24px;
            @media (max-width: 900px) {
                padding: 44px 16px;
            }
            .heading {
                font-size: 54px;
                font-weight: 700;
                color: transparent;
                background-clip: text;
                background-image: linear-gradient(90deg, #2d2a31 22%, #88315e 31.08%, #1b0c4d 39.01%, #5d476e 60.65%);
                margin-bottom: 28px;
                width: fit-content;
                @media (max-width: 900px) {
                    font-size: 34px;
                    margin-bottom: 20px;
                }
                @media (max-width: 440px) {
                    font-size: 28px;
                    margin-bottom: 16px;
                }
            }
            
            .description {
                font-size: 20px;
                font-weight: 400;
                color: black;
                opacity: .76;
                line-height: 1.6;
                display: flex;
                flex-direction: column;
                gap: 16px;
                p {
                    margin: 0px;
                }
                @media (max-width: 900px) {
                    font-size: 16px;
                    gap: 12px;
                }

            }
        }
    }

}
