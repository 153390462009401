.open-menu-styles {
    position: fixed;
    background-color: white;
    margin: 0px;
    min-height: 124px;
    left: 0px;
    top: 0px;
    display: flex;
    justify-content: center;
    width: 100%;
    z-index: 4;
    pointer-events: auto;
    border-bottom: 1px solid #e1e1e1;
    .open-menu-nav-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 8px;
        padding-right: 16px;
    }
}

.navbar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 44px;
    @media (max-width: 820px) {
        margin-top: 32px;
    }
    @media (max-width: 440px) {
        margin-top: 24px;
    }
    .navbar-links {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 32px;
        font-size: 20px;
        font-weight: 500;
        .link {
            color: rgba(0, 0, 0, 0.8);
            cursor: pointer;
            transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
            pointer-events: auto;
            &:hover {
                transform: translateY(-4px);
                color: black;
            }
        }
    }
    .navbar-menu-icon {
        display: flex;
        height: 44px;
        width: 44px;
        border-radius: 40px;
        pointer-events: auto;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:hover {
            background-color: rgba(0, 0, 0, 0.08);
        }
        svg {
            cursor: pointer;
        }
    }
    
}
.menu-overlay {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: fixed;
    top: 124px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 4;
    backdrop-filter: blur(20px);
    transform: translateY(0%);
    visibility: visible;
    opacity: 1;
    transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    // pointer-events: auto;
    .mobile-menu-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: white;
        // position: fixed;
        // width: 100%;
        // top: 0px;
        // left: 0px;
        // z-index: 4;
        .menu-option {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 24px 24px;
            font-size: 20px;
            border-bottom: 1px solid #EAEAEA;
            cursor: pointer;
            &:hover {
                background-color: #FAFAFA;
            }
        }
    }
}
.menu-overlay-hidden {
    transform: translateY(-200%);
    opacity: 0;
}
