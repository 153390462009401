.dots-wrap {
    display: flex;
    align-items: center;
    gap: 12px;
    .dot-item {
        width: 6px;
        height: 6px;
        border-radius: 12px;
        background-color: #6E6060;
        opacity: 0.24;
        cursor: pointer;
        pointer-events: auto;
        transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
        &:hover {
            transform: translateY(-4px);
        }
    }
}